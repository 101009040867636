<script setup>
import { useFacebookStore } from "@/stores/useFacebookStore";

const facebookStore = useFacebookStore();
const props = defineProps({
  href: {
    type: String,
    required: true,
  },
});

facebookStore.initFacebookPlugin();
</script>

<template>
  <div>
    <div>
      <h3 class="d-flex justify-start">
        {{ $t("QA") }}
      </h3>
      <div class="line" />
    </div>
    <div class="fb-comment-container mt-2">
      <div
      class="fb-comments"
      :data-href="href"
      data-width="100%"
      data-numposts="5"
    ></div>
    </div>
    
  </div>
</template>

<style scoped>
.line {
  border: 1px solid #b5e745;
  width: 100%;
}
.fb-comment-container {
  background-color: white;
}
</style>
